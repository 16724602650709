/**
 *
 * @param {[[keyPrefix, efConstant[]]]} constants
 */

export const getTranslationRequestObject = (efConstants) => {
    const transRequests = {};

    efConstants.forEach((efConstant) => {
        Object.entries(efConstant[1]).forEach(([key, val]) => {
            transRequests[`${efConstant[0]}_${val.value}`] = {
                blurbId: val.blurb,
                defaultVal: val.defaultLabel,
            };
        });
    });

    return transRequests;
};
